// Set your brand colors
$white: #fff;
$opacity-blue: #E8F1FD;
$light-blue: #cdddf6;
$cyan-blue: #aacff3;
$blue: #004990;
$green: #46b450;
$burgandy:#831618;
$light-grey: #e6e6e6;
$grey: #666666;
$dark-grey: #777;
$black: #1E1E1E;

// Update Bulma's global variables
$grey-light: $grey;
$primary: $burgandy;
$link: $burgandy;

