.navbar-menu {
  justify-content: space-evenly;
}

.navbar-start {
  justify-content: flex-start;
  margin-right: unset;
}

.navbar-item {
  @media (max-width: 1008px) {
    @include linx($black, $black, $black, $black);
  }
  @media (min-width: 1009px) {
    color: $white;
    @include linx($white, $white, $white, $white);
  }

  img {
    max-height: unset;
    width: 170px;
    height: 70px;
  }
  .navbar-dropdown {
    @include linx($black, $black, $black, $black);
  }
}

.nav-icon {
  @media (max-width: 1008px) {
    display: none;
  }
}
