.hero {
  &.has-background {
    position: relative;
    overflow: hidden;
  }

  &-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -100;
  }
}

.hero-quote-box {
  @include linx($burgandy, $burgandy, $burgandy, $burgandy);

  padding: 30px;
  border: 10px solid $white;
  margin: 75px 50px 75px 0;
  height: 512px;
  width: 336px;
  border-radius: 5px;
  line-height: 1.625;
  background-color: $opacity-blue;
  text-align: center;

  h1 {
    font-size: 24px;
  }

  h2 {
    margin: 0 0 10px;
    letter-spacing: 2px;
    color: #363636;
    font-weight: 600;
    text-transform: uppercase;
  }

  h3 {
    line-height: 1.2;
    color: $blue;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  h4 {
    margin: 0 0 25px;
    color: #363636;
    font-size: 18px;
    font-style: italic;
  }

  h5 {
    font-size: 17px;
  }

  strong {
    font-weight: bold;
  }

  form {
    margin-bottom: 20px;
  }

  select {
    padding: 0.75rem 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }

  input {
    padding: 0.75rem 70px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    background-color: $burgandy;
    cursor: pointer;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.hero-text {
  // margin: 50px 0 100px;
  line-height: 1.2;
  letter-spacing: 3px;
  color: $burgandy;
  font-weight: 600;

  @media (max-width: $tablet) {
    padding: 20px 0 10px;
    margin: 0 0 16px;
    font-size: 28px;
  }

  @media (min-width: $tablet + 1px) {
    font-size: 44px;
  }
}

.hero-text-space {
  margin: 240px;
}
