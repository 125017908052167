.quote-box-wrap {
  padding: 20px 0;
  border-radius: 5px;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  background-color: $light-blue;
}

.quote-box {
  @include linx($burgandy, $burgandy, $burgandy, $burgandy);

  padding: 30px;
  border: 10px solid $white;
  border-radius: 5px;
  margin: 10px 10px 46px 10px;
  line-height: 1.625;
  background-color: $opacity-blue;
  text-align: center;

  h1 {
    margin: 0 0 10px;
    letter-spacing: 2px;
    color: #363636;
    font-weight: 600;
    text-transform: uppercase;
  }

  h2 {
    line-height: 1.2;
    letter-spacing: 1px;
    color: $blue;
    font-size: 40px;
    font-weight: 600;
  }

  h3 {
    margin: 0 0 25px;
    color: #363636;
    font-size: 18px;
    font-style: italic;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 24px;
  }

  strong {
    font-weight: bold;
  }

  form {
    margin-bottom: 20px;
  }

  select {
    padding: 0.75rem 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    background-color: #fff;
  }

  input {
    padding: 0.75rem;
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    width: 100%;
    background-color: $burgandy;
    color: #fff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.submit-form {
  background-color: $burgandy !important;
}

.privacy-policy-link-text {
  font-size: 18px;
}