.header-display {
  @media (min-width: 1009px) {
    display: none;
  }
  padding: 20px 0;
}
.columns {
  margin-left: unset;
  margin-right: unset;
}

header {
  @include linx($burgandy, $burgandy, $burgandy, $burgandy);
}
