.form-page {
  @media (max-width: 1008px) {
    border-top: solid 5px $burgandy;
  }
  border-bottom: solid 5px $burgandy;
}

.inner {
  padding-top: 20px;

  @media (max-width: $tablet) {
    padding: 0 5%;
  }

  @media (min-width: $tablet + 1px) {
    background-color: #eee;
  }
}

.form-full {
  @media (min-width: $tablet + 1px) {
    padding: 25px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 6px 3px #ddd;
    opacity: 1;
    background-color: #fff;
  }

  h1 {
    padding: 25px 0;
    font-size: 35px;
    font-weight: 600;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
    font-weight: 600;
    text-align: left;

    @media (max-width: $tablet) {
      padding: 25px 0;
    }

    @media (min-width: $tablet + 1px) {
      padding: 25px;
    }
  }

  .form-paragraph {
    font-size: 25px;
    font-weight: 600;
    text-align: left;

    @media (max-width: $tablet) {
      padding: 25px 0;
    }

    @media (min-width: $tablet + 1px) {
      padding: 25px;
    }
  }

  h5 {
    font-size: 20px;
    line-height: 1.625;
  }

  .availablility {
    font-size: 20px;
    line-height: 1.625;
  }

  h6 {
    font-size: 23px;
  }
  .form-header {
    font-size: 23px;
  }

  a {
    @include linx($red, $red, $red, $red);
  }

  p {
    @media (max-width: $tablet) {
      margin: 10px 0;
    }

    @media (min-width: $tablet + 1px) {
      margin: 15px 0;
    }

    input[type="text"] {
      padding: 12px;
      border-radius: 5px;
      border: 1px solid #ccc;
      box-shadow: 0 0 5px #ddd inset;
      width: 100%;
      background-color: #fff;
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  label {
    margin-bottom: 20px;
  }

  select {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 0 5px #ddd inset;
    background-color: #fff;
  }
}

.another-car {
  margin-bottom: 20px;
  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: $blue;
  }
}

.car {
  transition: all 0.5s ease-in-out;
}
.arrow-down {
  border: 3px solid $blue;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  display: inline-block;
  height: 0.625em;
  pointer-events: none;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.625em;
}

.arrow-up {
  border: 3px solid $blue;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  display: inline-block;
  height: 0.625em;
  pointer-events: none;
  top: 50%;
  transform: rotate(135deg);
  transform-origin: center;
  width: 0.625em;
}

.submit-form {
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  width: 100%;
  background-color: $red;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;

  @media (min-width: $tablet + 1px) {
    margin-bottom: 20px;
  }
}

.submitted {
  padding: 20px;
  border: 2px solid $green;
  @media (max-width: $tablet) {
    margin-top: 16px;
  }
}

.divider {
  margin: 40px 0;
  height: 1px;
  clear: both;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(white),
    to(white),
    color-stop(50%, grey)
  );
}

.split {
  margin-bottom: 12px;
}

.contact-us-form {
  margin: 12px 0;
}

.call-us {
  color: $burgandy;
  font-size: 36px;
  font-weight: 500;
}

.about-us-text {
  margin-top: 26px;
  font-size: 20px;
  line-height: 1.625;
}

.headshot-container {
  @media (min-width: $tablet + 1px) {
    width: 90px;
    padding: 0 80px;
  }
}

.textMessageOptIn {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  input[type="radio"] {
    margin-right: 4px;
  }
}

.privacy-policy {
  .bold-header {
    display: inline-block;
    font-family: "Acumin-BdItPro";
    margin-bottom: 16px;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  div {
    font-family: "Acumin-RPro";
    font-size: 16px;
  }

  .second-paragraph {
    margin-top: 16px;
  }

  ul {
    list-style: circle;
    margin: 8px 0 16px 24px;
    li {
      font-family: "Acumin-RPro";
      font-size: 16px;
    }
  }

  .numbered-list {
    list-style: decimal;
  }

  .no-margin-bottom {
    margin-bottom: 0;
  }
}

.privacy-policy-link-container {
  margin-top: 12px;
}

.privacy-policy-link {
  color: #004990;
  &:hover {
    color: #004990;
    text-decoration: underline;
  }
}
