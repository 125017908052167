.footer-contact-us {
  @include linx($cyan-blue, $cyan-blue, $cyan-blue, $cyan-blue);

  padding: 25px 0 50px;
  line-height: 35px;
  background-color: $burgandy;
  color: $white;

  @media (max-width: $tablet) {
    border-radius: 5px;
  }

  h1 {
    margin: 25px;
    font-size: 25px;
    text-align: center;
  }
}

.watermark {
  margin: 20px 0 10px 0;
  font-size: 10px;
  text-align: center;

  @media (max-width: $tablet) {
    border-radius: 5px;
  }
}
